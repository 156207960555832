import React, { useContext } from "react";
import ABClogo from "../../assets/images/ABC-logo.png";
import { Link } from "react-router-dom";
import { LanguageContext } from "../../App";
import variousNames from "../../utils/variousNames";

const HeaderTop: React.FC = () => {
  const { language, setLanguage } = useContext(LanguageContext);
  
  const varNames = variousNames[language];
   
  return (<>
    <div className="adress-cell">
        <p className="adress-text">
          {varNames.adressContent}
        </p>
        <p className="adress-text">
            {varNames.phone}:
            <a className="adress-phone" href="tel:+371 20248447"> +371 20248447</a>
          </p>
      </div>
    <div className="logo-row">
      <div className="logo-cell" id="logo">
      <Link className="home-link" to={`${language}`}>
          <img className="logo" src={ABClogo} alt="ABC Logo" />
        </Link>
      </div>
      
      <div className="langv-cell" id="langv">
        {/*<i className="fas fa-search icon"></i>*/}
        <a
          href="https://www.facebook.com/288574291283020"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-facebook icon"></i>
        </a>
        <span className="langv">
          <button className="langv-btn" onClick={() => setLanguage("lv")}>
            LV
          </button>
          <button className="langv-btn" onClick={() => setLanguage("en")}>
            EN
          </button>
          <button className="langv-btn" onClick={() => setLanguage("ru")}>
            RU
          </button>
        </span>
      </div>
    </div></>
  );
};

export default HeaderTop;
