import { Language } from "../types/types";

interface LinkNames {
    about: string;
    company: string;
    team: string;
    documents: string;
    services:string;
    courses: string;
    qualification: string;
    companies: string;
    unemployed: string;
    howToChoose: string;
    certification: string;
    learner: string;
    faq: string;
    contacts: string;
    sitemap: string;
    tig: string;
    mag: string;
    mig: string;
    mma: string;

}

const linkNames: Record<Language, LinkNames> = {
  lv: {
    about: "par-mums",
    company: "uznemums",
    team: "komanda",
    documents: "dokumenti",
    services:"pakalpojumi",
    courses: "kursi",
    qualification: "kvalifikacija",
    companies: "uznemumiem",
    unemployed: "bezdarbniekiem",
    howToChoose: "ka-izveleties",
    certification: "sertifikacija",
    learner: "izglitojamajiem",
    faq: "buj",
    contacts: "kontakti",
    sitemap: "lapas-karte",
    tig: "tig",
    mag: "mag",
    mig: "mig",
    mma: "mma",
  },
  en: {
    about: "about",
    company: "company",
    team: "team",
    documents: "documents",
    services:"services",
    courses: "courses",
    qualification: "qualification",
    companies: "companies",
    unemployed: "unemployed",
    howToChoose: "how-to-choose",
    certification: "certification",
    learner: "learner",
    faq: "faq",
    contacts: "contacts",
    sitemap: "sitemap",
    tig: "tig",
    mag: "mag",
    mig: "mig",
    mma: "mma",
    
  },
  ru: {
    about: "o-nas",
    company: "kompaniya",
    team: "komanda",
    documents: "dokumenty",
    services:"uslugi",
    courses: "kursy",
    qualification: "kvalifikaciya",
    companies: "kompanii",
    unemployed: "bezrabotnye",
    howToChoose: "kak-vybrat",
    certification: "sertifikaciya",
    learner: "dlya-uchashchikhsya",
    faq: "faq",
    contacts: "kontakty",
    sitemap: "karta",
    tig: "tig",
    mag: "mag",
    mig: "mig",
    mma: "mma",
  },
};

export default linkNames;
