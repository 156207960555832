import React from 'react';
import HeaderTop from './HeaderTop';
import Nav from './Nav';



const Header: React.FC = () => {
  return (
   
      <header className="header">
        <HeaderTop />
        <Nav />
      </header>
    );
  };
  

export default Header;