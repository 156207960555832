import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { LanguageContext } from "../App";
import navigationNames from "../utils/navigationNames";
import linkNames from "../utils/linkNames";
import variousNames from "../utils/variousNames";

const Sitemap: React.FC = () => {

  const { language } = useContext(LanguageContext);

  const navNames = navigationNames[language];
  const varNames = variousNames[language];
  const links = linkNames[language];

  return (
    <div className="article">
      <h2>{varNames.sitemap}</h2>
      <ul className="map-list">
        <li>
          <Link to={`/${language}`}>{navNames.home}</Link>
        </li>
        <li>
          <Link to={`/${language}/${links.about}`}>{navNames.about}</Link>
        </li>
        <li>
          <ul className="map-list">
            <li>
              <Link to={`/${language}/${links.about}/${links.company}`}>{navNames.company}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.about}/${links.team}`}>{navNames.team}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.about}/${links.documents}`}>{navNames.documents}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.about}/${links.services}`}>{navNames.services}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={`/${language}/${links.courses}`}>{navNames.courses}</Link>
        </li>
        <li>
          <ul className="map-list">
            <li>
              <Link to={`/${language}/${links.courses}/tig`}>TIG</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/mag`}>MAG</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/mig`}>MIG</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/mma`}>MMA</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/${links.qualification}`}>{navNames.qualification}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/${links.companies}`}>{navNames.companies}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/${links.unemployed}`}>{navNames.unemployed}</Link>
            </li>
            <li>
              <Link to={`/${language}/${links.courses}/${links.howToChoose}`}>{navNames.howToChoose}</Link>
            </li>
          </ul>
        </li>
        <li>
          <Link to={`/${language}/${links.certification}`}>{navNames.sertification}</Link>
        </li>
        <li>
          <Link to={`/${language}/${links.learner}`}>{navNames.learner}</Link>
        </li>
        <li>
          <Link to={`/${language}/${links.faq}`}>{navNames.faq}</Link>
        </li>
        <li>
          <Link to={`/${language}/${links.contacts}`}>{navNames.contacts}</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sitemap;
