import React from "react";

import Content from "../components/Content";


const Sertification: React.FC = () => {
 
    return <Content type={'sertification'} />;
 
};

export default Sertification;