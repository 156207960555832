import { Language } from "../types/types";

interface NavigationNames {
  home: string;
  about: string;
  company: string;
  team: string;
  documents: string;
  services: string;
  courses: string;
  tig: string;
  mag: string;
  mig: string;
  mma: string;
  qualification: string;
  companies: string;
  unemployed: string;
  howToChoose: string;
  sertification: string;
  learner: string;
  faq: string;
  contacts: string;
}

const navigationNames: Record<Language, NavigationNames> = {
  lv: {
    home: "Sākums",
    about: "Par mums",
    company: "Uzņēmums",
    team: "Mūsu komanda",
    documents: "Dokumenti",
    services: "Metināšanas pakalpojumi",
    courses: "Kursi",
    tig: "TIG",
    mag: "MAG",
    mig: "MIG",
    mma: "MMA",
    qualification: "Kvalifikācijas celšana",
    companies: "Uzņēmumiem",
    unemployed: "Bezdarbniekiem",
    howToChoose: "Kā izvēlēties veidu",
    sertification: "Sertifikācija",
    learner: "Izglītojamajiem",
    faq: "BUJ",
    contacts: "Kontakti",
  },
  en: {
    home: "Home",
    about: "About Us",
    company: "Company",
    team: "Our Team",
    documents: "Documents",
    services: "Welding Services",
    courses: "Courses",
    tig: "TIG",
    mag: "MAG",
    mig: "MIG",
    mma: "MMA",
    qualification: "Enhancing Qualifications",
    companies: "For Companies",
    unemployed: "For the Unemployed",
    howToChoose: "How to Choose the Type",
    sertification: "Certification",
    learner: "For Learners",
    faq: "FAQ",
    contacts: "Contacts",
  },
  ru: {
    home: "Главная",
    about: "О нас",
    company: "Компания",
    team: "Наша команда",
    documents: "Документы",
    services: "Сварочные услуги",
    courses: "Курсы",
    tig: "TIG",
    mag: "MAG",
    mig: "MIG",
    mma: "MMA",
    qualification: "Повышение квалификации",
    companies: "Для компаний",
    unemployed: "Для безработных",
    howToChoose: "Как выбрать тип",
    sertification: "Сертификация",
    learner: "Для учащихся",
    faq: "Вопросы и ответы",
    contacts: "Контакты",
  },
};

export default navigationNames;
