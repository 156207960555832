import { Language } from "../types/types";

interface VariousNames {
  faq: string;
  footer: string;
  articleFooter: string;
  articleFooterPpl: string;
  adress: string;
  adressContent: string;
  email: string;
  details: string;
  detailsContent: string;
  phone: string;
  contacts: string;
  contactUs: string;
  sitemap: string;
  nextCourses: string;
}

const variousNames: Record<Language, VariousNames> = {
  lv: {
    faq: "Biežāk uzdotie jautājumi",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Izstrādājusi Dace Troice",
    articleFooter:
      "Sīkāka informācija pa tālruni: 20248447 vai e-pastā: info@metinasana.lv",
    articleFooterPpl:
      "Sīkāka informācija pa tālruni: 20248447 vai e-pastā: info@ppl.lv",
    adress: "Adrese",
    adressContent: "Krišjāņa Barona iela 130 k-2, Rīga",
    email: "E-pasts",
    details: "Rekvizīti",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Tālr.",
    contacts: "Kontakti",
    contactUs: "Sazinies ar mums",
    sitemap: "Lapas karte",
    nextCourses: "Nākošie kursi sāksies",
  },
  en: {
    faq: "Frequently Asked Questions",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Developed by Dace Troice | All Rights Reserved",
    articleFooter:
      "For more information, call: 20248447 or email: info@metinasana.lv",
    articleFooterPpl:
      "For more information, call: 20248447 or email: info@ppl.lv",
    adress: "Address",
    adressContent: "Kr. Barona Street 130, k-2, Riga",
    email: "Email",
    details: "Details",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Phone",
    contacts: "Contacts",
    contactUs: "Contact Us",
    sitemap: "Site Map",
    nextCourses: "Next courses will start",
  },
  ru: {
    faq: "Часто задаваемые вопросы",
    footer:
      "© 2024  Mācību un konsultāciju centrs ABC | Разработалa Dace Troice",
    articleFooter:
      "Дополнительную информацию можно получить по телефону: 20248447 или по электронной почте: info@metinasana.lv",
    articleFooterPpl:
      "Дополнительную информацию можно получить по телефону: 20248447 или по электронной почте: info@ppl.lv",
    adress: "Адрес",
    adressContent: "Ул. Кр. Барона 130, k-2, Рига",
    email: "Эл. почта",
    details: "Реквизиты",
    detailsContent: " Reģ. Nr.: 45403009746 Mācību centra adrese: Krišjāņa Barona iela 130 k-2, Rīga, LV-1012",
    phone: "Тел.",
    contacts: "Контакты",
    contactUs: "Свяжитесь с нами",
    sitemap: "Карта сайта",
    nextCourses: "Следующие курсы начнутся",
  },
};

export default variousNames;
